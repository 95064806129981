export const HOURS_24 = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
// tslint:disable-next-line:max-line-length
export const MINUTES = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

// Warning: This must be in sync with the TIMEZONES_OFFSET object
export const TIMEZONES: Record<string, string> = {
  "Pacific/Midway": "(GMT-11:00) Midway Island",
  "US/Samoa": "(GMT-11:00) Samoa",
  "US/Hawaii": "(GMT-10:00) Hawaii",
  "US/Alaska": "(GMT-09:00) Alaska",
  "US/Pacific": "(GMT-08:00) Pacific Time (US &amp; Canada)",
  "America/Tijuana": "(GMT-08:00) Tijuana",
  "US/Arizona": "(GMT-07:00) Arizona",
  "US/Mountain": "(GMT-07:00) Mountain Time (US &amp; Canada)",
  "America/Chihuahua": "(GMT-07:00) Chihuahua",
  "America/Mazatlan": "(GMT-07:00) Mazatlan",
  "America/Mexico_City": "(GMT-06:00) Mexico City",
  "America/Monterrey": "(GMT-06:00) Monterrey",
  "Canada/Saskatchewan": "(GMT-06:00) Saskatchewan",
  "US/Central": "(GMT-06:00) Central Time (US &amp; Canada)",
  "US/Eastern": "(GMT-05:00) Eastern Time (US &amp; Canada)",
  "US/East-Indiana": "(GMT-05:00) Indiana (East)",
  "America/Bogota": "(GMT-05:00) Bogota",
  "America/Lima": "(GMT-05:00) Lima",
  "America/Caracas": "(GMT-04:30) Caracas",
  "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
  "America/La_Paz": "(GMT-04:00) La Paz",
  "America/Santiago": "(GMT-04:00) Santiago",
  "Canada/Newfoundland": "(GMT-03:30) Newfoundland",
  "America/Buenos_Aires": "(GMT-03:00) Buenos Aires",
  Greenland: "(GMT-03:00) Greenland",
  "Atlantic/Stanley": "(GMT-02:00) Stanley",
  "Atlantic/Azores": "(GMT-01:00) Azores",
  "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde Is.",
  "Africa/Casablanca": "(GMT) Casablanca",
  "Europe/Dublin": "(GMT) Dublin",
  "Europe/Lisbon": "(GMT) Lisbon",
  "Europe/London": "(GMT) London",
  "Africa/Monrovia": "(GMT) Monrovia",
  "Europe/Amsterdam": "(GMT+01:00) Amsterdam",
  "Europe/Belgrade": "(GMT+01:00) Belgrade",
  "Europe/Berlin": "(GMT+01:00) Berlin",
  "Europe/Bratislava": "(GMT+01:00) Bratislava",
  "Europe/Brussels": "(GMT+01:00) Brussels",
  "Europe/Budapest": "(GMT+01:00) Budapest",
  "Europe/Copenhagen": "(GMT+01:00) Copenhagen",
  "Europe/Ljubljana": "(GMT+01:00) Ljubljana",
  "Europe/Madrid": "(GMT+01:00) Madrid",
  "Europe/Paris": "(GMT+01:00) Paris",
  "Europe/Prague": "(GMT+01:00) Prague",
  "Europe/Rome": "(GMT+01:00) Rome",
  "Europe/Sarajevo": "(GMT+01:00) Sarajevo",
  "Europe/Skopje": "(GMT+01:00) Skopje",
  "Europe/Stockholm": "(GMT+01:00) Stockholm",
  "Europe/Vienna": "(GMT+01:00) Vienna",
  "Europe/Warsaw": "(GMT+01:00) Warsaw",
  "Europe/Zagreb": "(GMT+01:00) Zagreb",
  "Europe/Athens": "(GMT+02:00) Athens",
  "Europe/Bucharest": "(GMT+02:00) Bucharest",
  "Africa/Cairo": "(GMT+02:00) Cairo",
  "Africa/Harare": "(GMT+02:00) Harare",
  "Europe/Helsinki": "(GMT+02:00) Helsinki",
  "Europe/Istanbul": "(GMT+02:00) Istanbul",
  "Asia/Jerusalem": "(GMT+02:00) Jerusalem",
  "Europe/Kiev": "(GMT+02:00) Kyiv",
  "Europe/Minsk": "(GMT+02:00) Minsk",
  "Europe/Riga": "(GMT+02:00) Riga",
  "Europe/Sofia": "(GMT+02:00) Sofia",
  "Europe/Tallinn": "(GMT+02:00) Tallinn",
  "Europe/Vilnius": "(GMT+02:00) Vilnius",
  "Asia/Baghdad": "(GMT+03:00) Baghdad",
  "Asia/Kuwait": "(GMT+03:00) Kuwait",
  "Africa/Nairobi": "(GMT+03:00) Nairobi",
  "Asia/Riyadh": "(GMT+03:00) Riyadh",
  "Europe/Moscow": "(GMT+03:00) Moscow",
  "Asia/Tehran": "(GMT+03:30) Tehran",
  "Asia/Baku": "(GMT+04:00) Baku",
  "Europe/Volgograd": "(GMT+04:00) Volgograd",
  "Asia/Muscat": "(GMT+04:00) Muscat",
  "Asia/Tbilisi": "(GMT+04:00) Tbilisi",
  "Asia/Yerevan": "(GMT+04:00) Yerevan",
  "Asia/Kabul": "(GMT+04:30) Kabul",
  "Asia/Karachi": "(GMT+05:00) Karachi",
  "Asia/Tashkent": "(GMT+05:00) Tashkent",
  "Asia/Kolkata": "(GMT+05:30) Kolkata",
  "Asia/Kathmandu": "(GMT+05:45) Kathmandu",
  "Asia/Yekaterinburg": "(GMT+06:00) Ekaterinburg",
  "Asia/Almaty": "(GMT+06:00) Almaty",
  "Asia/Dhaka": "(GMT+06:00) Dhaka",
  "Asia/Novosibirsk": "(GMT+07:00) Novosibirsk",
  "Asia/Bangkok": "(GMT+07:00) Bangkok",
  "Asia/Jakarta": "(GMT+07:00) Jakarta",
  "Asia/Krasnoyarsk": "(GMT+08:00) Krasnoyarsk",
  "Asia/Chongqing": "(GMT+08:00) Chongqing",
  "Asia/Hong_Kong": "(GMT+08:00) Hong Kong",
  "Asia/Kuala_Lumpur": "(GMT+08:00) Kuala Lumpur",
  "Australia/Perth": "(GMT+08:00) Perth",
  "Asia/Singapore": "(GMT+08:00) Singapore",
  "Asia/Taipei": "(GMT+08:00) Taipei",
  "Asia/Ulaanbaatar": "(GMT+08:00) Ulaan Bataar",
  "Asia/Urumqi": "(GMT+08:00) Urumqi",
  "Asia/Irkutsk": "(GMT+09:00) Irkutsk",
  "Asia/Seoul": "(GMT+09:00) Seoul",
  "Asia/Tokyo": "(GMT+09:00) Tokyo",
  "Australia/Adelaide": "(GMT+09:30) Adelaide",
  "Australia/Darwin": "(GMT+09:30) Darwin",
  "Asia/Yakutsk": "(GMT+10:00) Yakutsk",
  "Australia/Brisbane": "(GMT+10:00) Brisbane",
  "Australia/Canberra": "(GMT+10:00) Canberra",
  "Pacific/Guam": "(GMT+10:00) Guam",
  "Australia/Hobart": "(GMT+10:00) Hobart",
  "Australia/Melbourne": "(GMT+10:00) Melbourne",
  "Pacific/Port_Moresby": "(GMT+10:00) Port Moresby",
  "Australia/Sydney": "(GMT+10:00) Sydney",
  "Asia/Vladivostok": "(GMT+11:00) Vladivostok",
  "Asia/Magadan": "(GMT+12:00) Magadan",
  "Pacific/Auckland": "(GMT+12:00) Auckland",
  "Pacific/Fiji": "(GMT+12:00) Fiji",
};

export interface TimezoneOffset {
  readonly name: string;
  readonly offset: number;
}

// Warning: This must be in sync with the TIMEZONES object
export const TIMEZONES_OFFSET: TimezoneOffset[] = [
  { name: "Pacific/Fiji", offset: -720 },
  { name: "Pacific/Auckland", offset: -720 },
  { name: "Asia/Magadan", offset: -720 },
  { name: "Asia/Vladivostok", offset: -660 },
  { name: "Australia/Sydney", offset: -600 },
  { name: "Pacific/Port_Moresby", offset: -600 },
  { name: "Australia/Melbourne", offset: -600 },
  { name: "Australia/Hobart", offset: -600 },
  { name: "Pacific/Guam", offset: -600 },
  { name: "Australia/Canberra", offset: -600 },
  { name: "Australia/Brisbane", offset: -600 },
  { name: "Asia/Yakutsk", offset: -600 },
  { name: "Australia/Darwin", offset: -570 },
  { name: "Australia/Adelaide", offset: -570 },
  { name: "Asia/Tokyo", offset: -540 },
  { name: "Asia/Seoul", offset: -540 },
  { name: "Asia/Irkutsk", offset: -540 },
  { name: "Asia/Urumqi", offset: -480 },
  { name: "Asia/Ulaanbaatar", offset: -480 },
  { name: "Asia/Taipei", offset: -480 },
  { name: "Asia/Singapore", offset: -480 },
  { name: "Australia/Perth", offset: -480 },
  { name: "Asia/Kuala_Lumpur", offset: -480 },
  { name: "Asia/Hong_Kong", offset: -480 },
  { name: "Asia/Chongqing", offset: -480 },
  { name: "Asia/Krasnoyarsk", offset: -480 },
  { name: "Asia/Jakarta", offset: -420 },
  { name: "Asia/Bangkok", offset: -420 },
  { name: "Asia/Novosibirsk", offset: -420 },
  { name: "Asia/Dhaka", offset: -360 },
  { name: "Asia/Almaty", offset: -360 },
  { name: "Asia/Yekaterinburg", offset: -360 },
  { name: "Asia/Kathmandu", offset: -345 },
  { name: "Asia/Kolkata", offset: -330 },
  { name: "Asia/Tashkent", offset: -300 },
  { name: "Asia/Karachi", offset: -300 },
  { name: "Asia/Kabul", offset: -270 },
  { name: "Asia/Yerevan", offset: -240 },
  { name: "Asia/Tbilisi", offset: -240 },
  { name: "Asia/Muscat", offset: -240 },
  { name: "Europe/Volgograd", offset: -240 },
  { name: "Asia/Baku", offset: -240 },
  { name: "Asia/Tehran", offset: -210 },
  { name: "Europe/Moscow", offset: -180 },
  { name: "Asia/Riyadh", offset: -180 },
  { name: "Africa/Nairobi", offset: -180 },
  { name: "Asia/Kuwait", offset: -180 },
  { name: "Asia/Baghdad", offset: -180 },
  { name: "Europe/Vilnius", offset: -120 },
  { name: "Europe/Tallinn", offset: -120 },
  { name: "Europe/Sofia", offset: -120 },
  { name: "Europe/Riga", offset: -120 },
  { name: "Europe/Minsk", offset: -120 },
  { name: "Europe/Kiev", offset: -120 },
  { name: "Asia/Jerusalem", offset: -120 },
  { name: "Europe/Istanbul", offset: -120 },
  { name: "Europe/Helsinki", offset: -120 },
  { name: "Africa/Harare", offset: -120 },
  { name: "Africa/Cairo", offset: -120 },
  { name: "Europe/Bucharest", offset: -120 },
  { name: "Europe/Athens", offset: -120 },
  { name: "Europe/Amsterdam", offset: -60 },
  { name: "Europe/Belgrade", offset: -60 },
  { name: "Europe/Berlin", offset: -60 },
  { name: "Europe/Bratislava", offset: -60 },
  { name: "Europe/Brussels", offset: -60 },
  { name: "Europe/Budapest", offset: -60 },
  { name: "Europe/Copenhagen", offset: -60 },
  { name: "Europe/Ljubljana", offset: -60 },
  { name: "Europe/Madrid", offset: -60 },
  { name: "Europe/Paris", offset: -60 },
  { name: "Europe/Prague", offset: -60 },
  { name: "Europe/Rome", offset: -60 },
  { name: "Europe/Sarajevo", offset: -60 },
  { name: "Europe/Skopje", offset: -60 },
  { name: "Europe/Stockholm", offset: -60 },
  { name: "Europe/Vienna", offset: -60 },
  { name: "Europe/Warsaw", offset: -60 },
  { name: "Europe/Zagreb", offset: -60 },
  { name: "Africa/Monrovia", offset: 0 },
  { name: "Europe/London", offset: 0 },
  { name: "Europe/Lisbon", offset: 0 },
  { name: "Europe/Dublin", offset: 0 },
  { name: "Africa/Casablanca", offset: 0 },
  { name: "Atlantic/Cape_Verde", offset: 60 },
  { name: "Atlantic/Azores", offset: 60 },
  { name: "Atlantic/Stanley", offset: 120 },
  { name: "Greenland", offset: 180 },
  { name: "America/Buenos_Aires", offset: 180 },
  { name: "Canada/Newfoundland", offset: 310 },
  { name: "America/Santiago", offset: 240 },
  { name: "America/La_Paz", offset: 240 },
  { name: "Canada/Atlantic", offset: 240 },
  { name: "America/Caracas", offset: 270 },
  { name: "America/Lima", offset: 300 },
  { name: "America/Bogota", offset: 300 },
  { name: "US/East-Indiana", offset: 300 },
  { name: "US/Eastern", offset: 300 },
  { name: "US/Central", offset: 360 },
  { name: "Canada/Saskatchewan", offset: 360 },
  { name: "America/Monterrey", offset: 360 },
  { name: "America/Mexico_City", offset: 360 },
  { name: "America/Mazatlan", offset: 420 },
  { name: "America/Chihuahua", offset: 420 },
  { name: "US/Mountain", offset: 420 },
  { name: "US/Arizona", offset: 420 },
  { name: "America/Tijuana", offset: 480 },
  { name: "US/Pacific", offset: 480 },
  { name: "US/Alaska", offset: 540 },
  { name: "US/Hawaii", offset: 600 },
  { name: "US/Samoa", offset: 660 },
  { name: "Pacific/Midway", offset: 660 },
];
